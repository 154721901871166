import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

class PartsChoosePath extends React.Component {
    render () {
        return (
            <div className="grid-container main-content">
                <h2>What are you looking for?</h2>
                <Link to="/parts/search" className="general-button">Hub Components by Assembly</Link>
                <a href="https://cdn.intelligencebank.com/us/share/ONnl/L8B8/aKk2/original/Cross_WheelBearings" target="_blank" className="general-button" rel="noopener noreferrer">Bearing Cross Reference</a>
                <a href="https://cdn.intelligencebank.com/us/share/ONnl/L8B8/VYZMD/original/CROSS_WheelSeals_2022" target="_blank" className="general-button" rel="noopener noreferrer">Seal Cross Reference</a>
                <a href="https://cdn.intelligencebank.com/us/share/ONnl/L8B8/PV0v/original/CROSS_PreSetPlus_SpindleNuts" target="_blank" className="general-button" rel="noopener noreferrer">Spindle Nut Reference</a>
                {/*<a href="https://hubcatalog.conmet.com/StudChart.aspx" target="_blank" className="general-button">Stud Chart</a>*/}

            </div>
        );
    }
}

export default connect()(PartsChoosePath);
